<template>
  <div class="list">
    <div class="item" v-for="(item, index) in list" :key="index">
      <div class="title">
        <div class="left">
          <i></i>
          <p>{{ item.benefitName }}</p>
        </div>
        <div class="right">
          <p>{{ item.insuredAmountShow }}</p>
        </div>
      </div>
      <!-- <div class="price">
        <p class="tit">保险金额</p>
        <div class="list">
          <div class="name" v-for="(item1,index1) in item.insuredAmountShowArr" :key="index1">{{ item1 }}</div>
        </div>
      </div> -->
      <div class="detail" v-if="item.benefitDetails">
        <div class="msg">
          <p class="tit">保险说明</p>
          <!-- <rich-text style="width: 100%; word-break: break-all; white-space: pre-warp" type="text" :nodes="item.benefitDetails | formatRichText"></rich-text> -->
          <!-- <p>{{ item.benefitDetails }}</p> -->
          <p v-html="item.benefitDetails"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productBenefitDetail } from "../utils/api"
// import rrwebPlayer from 'rrweb-player'
export default {
  components: {},
  data() {
    return {
      list: [],
    }
  },
  async created() {
    this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    })
    await this.productBenefitDetail()
    this.$toast.clear()
  },
  // 过滤符文本
  filters: {
    formatRichText(html) {
      if (html != null) {
        html.replace(/\<p/gi, '<img style="padding:0 20px;"')
        //控制小程序中图片大小
        let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
          match = match.replace(/style="[^"]+"/gi, "").replace(/style='[^']+'/gi, "")
          match = match.replace(/width="[^"]+"/gi, "").replace(/width='[^']+'/gi, "")
          match = match.replace(/height="[^"]+"/gi, "").replace(/height='[^']+'/gi, "")
          return match
        })
        newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
          match = match.replace(/width:[^;]+;/gi, "max-width:100%;").replace(/width:[^;]+;/gi, "max-width:100%;")
          return match
        })
        newContent = newContent.replace(/<br[^>]*\/>/gi, "")
        newContent = newContent.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:inline-block;display:block"')

        return newContent
      }
    },
  },
  methods: {
    //权益详情
    productBenefitDetail() {
      let params = {
        plansId: this.$route.query.id,
      }
      productBenefitDetail(params).then((res) => {
        if (res.success) {
          // this.list = res.result;
          // res.result.map((item) => {
          //   let arr = item.insuredAmountShow.split(";");
          //   item.insuredAmountShowArr = arr;
          // });

          // let obj = {
          //   benefitName: "累计给付限额",
          //   insuredAmountShow: this.$route.query.price,
          // }
          // res.result.push(obj)
          this.list = res.result
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.list {
  padding-bottom: 50px;
}
.item {
  padding-top: 13px;
  padding-bottom: 7px;
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    i {
      width: 4px;
      height: 16px;
      background: $primary-color;
      border-radius: 0px 2px 2px 0px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      margin-left: 6px;
    }
  }
  .right {
    padding-right: 10px;
    p {
      font-size: 16px;
      font-weight: 500;
      color: $primary-color;
      line-height: 22px;
    }
  }
}
.price {
  margin: 10px 10px 0;
  padding: 12px 12px 16px;
  border-radius: 4px;
  background: $bg7;
  .tit {
    font-size: 15px;
    font-weight: bold;
    color: #333333;
    line-height: 21px;
  }
  .list {
    margin-top: 10px;
    .name {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
  }
}
.detail {
  margin: 10px 10px 0;
  .msg {
    padding: 12px 12px;
    background: $bg7;
    border-radius: 4px;

    .tit {
      font-size: 15px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      text-align: justify;
    }
  }
}
</style>
